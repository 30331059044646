window.$ = jQuery;

(function ($) {
  $(document).ready(function () {
    console.log('custom js loaded!');

    $('.mens-group-banner-video').click(function(e) {
      e.preventDefault();
      console.log('toggleplay');
      var video = $(this).get(0);
      if (this.paused == true) {
        video.play();
      } else {
        video.pause();
      }
      //$(this).get(0).play();
    });

  }) // end of $(document).ready(function () {
})(jQuery);